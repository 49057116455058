body{
    background-color: rgba($color: #d8d8d8, $alpha: .8);
}
.Loading{
    text-align: center;
}
.Editor{
    &__container{
        background-color: #ffffff;
        margin: 100px 100px 100px 100px;
        padding: 50px;
        &--disabled{
            pointer-events: none;
            opacity: 0.6;
        }
    }
    &__header{
        position: fixed;
        top: 0px;
        width: 100%;
        text-align: right;
    }
}